import Vue from 'vue'
import Vuex from 'vuex'

import user from './module/user'
import app from './module/app'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		city: '',
		province: '',
		token: '',
		user: {},
	},
	mutations: {
		set_city(state, provider) {
			state.city = provider;
			localStorage.setItem("city", provider)
		},
		set_province(state, provider) {
			state.province = provider;
			localStorage.setItem("province", provider)
		},
		setLogin(state, provider) {
			state.user = provider;
			state.token = provider.access_token;
			localStorage.setItem("user", JSON.stringify(provider))
			localStorage.setItem("token", provider.access_token)
		},
		editUser(state, provider) {
			state.user = provider;
			localStorage.setItem("user", JSON.stringify(provider))
		},
		setLogout(state) {
			state.user = {};
			state.token = '';
			localStorage.removeItem('user');
			localStorage.removeItem('token');
		},
	},
	actions: {

	},
	getters: {
		city: state => {
			let city = state.city
			if (!city) {
				city = (window.localStorage.getItem('city') || null)
			}
			return city
		},
		province: state => {
			let province = state.province
			if (!province) {
				province = (window.localStorage.getItem('province') || null)
			}
			return province
		},
		user: state => {
			let user = state.user

			if (typeof(user.id) == 'undefined') {
				if (localStorage.getItem('user') != '') {
					user = JSON.parse(localStorage.getItem('user'))
				} else {
					user = {}
				}
			}
			return user
		},
		token: state => {
			let token = state.token
			if (!token) {
				token = (window.localStorage.getItem('token') || null)
			}
			return token
		},
	},
	modules: {
		user,
		app
	}
})
