<template>
	<div class="form_admin">
		<div class="head">
			<el-page-header @back="goBack" content="咨询管理">
			</el-page-header>
		</div>
		<div class="box">
			<div class="fl">
				<Left></Left>
			</div>
			<div class="fr">
				<div class="flex flex-column">
					<div class="search_form flex">
						搜索 &nbsp; <el-input v-model="param.keyword" placeholder="请输入内容"></el-input>
						创建时间:&nbsp;

						<el-date-picker v-model="select_time" type="datetimerange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>

						<el-button type="primary" @click="do_search()">查询</el-button>
						<el-button type="primary">
							<download-excel class="btn btn-default" :data="list" :fields="excel_title"
								worksheet="My Worksheet" type="csv" name="filename.xls"> 导出 </download-excel>
						</el-button>

					</div>
					<template>
						<el-table ref="multipleTable" :data="list" @selection-change="handleSelectionChange" stripe
							style="width: 100%">
							<el-table-column type="selection" width="55">
							</el-table-column>
							<el-table-column label="联系人" width="180">
								<template slot-scope="scope">
									{{scope.row.info.name}}
								</template>
							</el-table-column>
							<el-table-column label="联系电话" width="180">
								<template slot-scope="scope">
									{{scope.row.info.tel}}
								</template>
							</el-table-column>
							<el-table-column label="咨询内容" width="180">
								<template slot-scope="scope">
									{{scope.row.info.content}}
								</template>
							</el-table-column>
							<el-table-column prop="created_at" label="创建时间">
							</el-table-column>
							<el-table-column label="操作" width="180">
								<template slot-scope="scope">
									<div class="flex space-around">
										<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">查看
										</el-button>
										<el-popconfirm title="这是一段内容确定删除吗？"
											@confirm="handleDelete(scope.$index, scope.row)">
											<el-button slot="reference" size="mini" type="danger">
												删除</el-button>
										</el-popconfirm>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</template>
					<el-drawer v-if="detail&&detail.info" title="咨询详情" :visible.sync="drawer" :direction="direction"
						:before-close="handleClose">
						<div class="detail">
							<div class="li">
								需求内容：{{detail.info.content}}
							</div>
							<div class="li">联 系 人 ：{{detail.info.name}}</div>
							<div class="li">联系电话：{{detail.info.tel}}</div>
							<div class="li">提交时间：{{detail.created_at}}</div>
						</div>
					</el-drawer>
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
						layout="prev, pager, next" :page-size="param.limit" :total="this.count">
					</el-pagination>
					<div class="action">
						<el-button @click="del()">删除</el-button>
						<el-button @click="toggleSelection()">取消选择</el-button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import Left from "../user/Left.vue"
	export default {
		components: {
			Left
		},
		data() {
			return {
				select_time: [new Date(2021, 8, 0, 0, 0), new Date()],
				param: {
					page: 1,
					limit: 8,
					keyword: '',
					type: 10,
					create_time: []
				},
				drawer: false,
				direction: 'rtl',
				count: 0,
				list: [],
				detail: {},
				dialogVisible: false,
				multipleSelection: [],
				excel_title: {
					'联系人': "info.name",
					'联系电话': 'info.tel',
					'咨询内容': 'info.content',
					'发布日期': 'created_at',
				},
			}
		},
		mounted() {
			this.get_list();
		},
		methods: {
			handleCurrentChange(val) {
				this.param.page = val;
				this.get_list();
			},
			handleClose(done) {
				done();
			},
			handleEdit(index, row) {
				this.drawer = true;
				this.detail = row;
			},
			handleDelete(index, row) {
				this.delfun({
					id: row.id
				})
			},
			del() {
				let ids = []
				this.multipleSelection.forEach(function(v) {
					ids.push(v.id)
				})
				this.$confirm('确定删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.delfun({
						"ids": ids
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			delfun(param) {
				let that = this;
				this.$http_qm.post('form/del', param).then(res => {
					if (res.code == 200) {
						that.get_list();
						that.$message({
							type: 'success',
							message: '删除成功!'
						});
					}
				}).catch(err => {
					console.log(err);
				})

			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			do_search() {
				this.param.page = 1;
				this.get_list();
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(v) {
				this.multipleSelection = v
			},
			goBack() {
				// this.$router.back();
				this.$router.push({
					path: '/'
				})
			},
			get_list() {
				this.param.create_time[0] = this.select_time[0].getTime().toString();
				this.param.create_time[1] = this.select_time[1].getTime().toString();
				this.$http_qm.post('form/list', this.param).then(res => {
					this.list = res.data;
					this.count = res.count;
				}).catch(err => {
					console.log(err)
				})
			},
		}
	}
</script>

<style lang="less">
	.form_admin {
		background: #f5f5f5;
		display: flex;
		flex-direction: column;

		.box {

			width: 1200px;
			margin: 26px auto 0px;
			display: flex;

			.fl {
				width: 160px;
				background: #fff;
				text-align: center;
			}

			.fr {
				background: #fff;
				float: right;
				flex: 1;
				margin-left: 20px;
				min-height: 750px;

				.search_form {
					margin: 20px 10px;
					display: flex;
					align-items: center;

					.el-input {
						width: 200px;
						margin: 0px 10px;
					}

					.el-date-editor {
						margin: 0px 10px;
					}

					button {}
				}

				.action {
					margin: 20px 10px;
				}
			}

			.detail {
				margin: 0px 0px 0px 20px;

				.li {
					margin-bottom: 23px;
				}
			}
		}
	}
</style>
