<template>
	<div id="app">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive">
				<!-- 这里是会被缓存的视图组件，比如列表A页面 -->
			</router-view>
		</keep-alive>

		<router-view v-if="!$route.meta.keepAlive">
			<!-- 这里是不被缓存的视图组件，比如详情B页面-->
		</router-view>

		<Bottom
			v-show="!(path ==='/login'||path ==='/register'||path ==='/prize_draw' || path === '/news/add' || path === '/move') ">
		</Bottom>
	</div>
</template>

<script>
	import Bottom from "./components/bottom.vue"
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'App',
		components: {
			Bottom
		},
		beforeRouteEnter() {
			this.head_show = false
		},
		computed: {
			...mapGetters(['city', 'user'])
		},
		watch: {

		},
		data() {
			return {
				path: '',
				head_show: false,
				not_path: ['/login', '/register', '/prize_draw', '/expert'],
				first_path: ['/store', '/expert'],
			}
		},
		created() {
			this.path = this.$route.path;
		},
		mounted() {
			// console.log(this.$route);
			this.path = this.$route.path

		},

		methods: {},
	}
</script>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
<style lang="less">
	@import "assets/css/common.less";
	@import "assets/css/edit.css";
</style>
