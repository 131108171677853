function jsonSort(jsonObj) {
	let arr = [];
	for (var key in jsonObj) {
		arr.push(key)
	}
	arr.sort();
	let str = '';
	for (var i in arr) {
		str += arr[i] + "=" + jsonObj[arr[i]] + "&"
	}
	return str.substr(0, str.length - 1)
}
let test1 = 1
export default {
	test1,
	jsonSort
}
