<template>
	<div id="achievemenAdd">
		<div class="head">
			<el-page-header @back="goBack" content="项目管理">
			</el-page-header>
		</div>
		<div class="container">
			<div class="grzx_left">
				<Left></Left>
			</div>
			<div class="grzx-right fr">
				<div class="grzx-box">
					<div class="grzx-wxts">
						<div class="wxts-wzts">
							<h3>温馨提示：</h3>
							<p>○ 为了保证您的信息能顺利通过我们的审核，请将信息的真实情况全面的发布出来。</p>
							<p>○ 根据我们的长期跟踪统计，信息完整度越高，越容易获得目标客户的关注哦！</p>
							<p>○ 信息完整度越高，将在我们的平台搜索结果排序更靠前，以及获得推荐和享受增值服务试用的机会更大哦！</p>
						</div>
					</div>
					<el-form :model="ruleForm" label-position="left" :rules="rules" ref="ruleForm" label-width="180px"
						class="demo-ruleForm">
						<el-form-item label="技术名称" prop="title">
							<el-input class="from_name" v-model="ruleForm.title" placeholder="请输入您的技术名称,最多不超过35个字"
								maxlength="35" style="width: 380px;"></el-input>
						</el-form-item>
						<el-form-item label="项目编号" prop="number">
							<el-input class="from_name" v-model="ruleForm.number" placeholder="请输入您的项目编号" maxlength="50"
								style="width:157px;"></el-input>
						</el-form-item>
						<el-form-item label="产业领域" prop="industry">
							<el-select v-model="ruleForm.industry" placeholder="请选择" style="width: 200px;">
								<el-option v-for="(v,k) in cate.industry" :key='k' :value="v" :label="v">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="是否拥有知识产权" prop="property_right">
							<el-radio-group v-model="ruleForm.property_right">
								<el-radio label="是">是</el-radio>
								<el-radio label="否">否</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="项目简介" prop="xmhjsqk">

							<vue-editor style="width:690px;" v-model="ruleForm.xmhjsqk" @focus="onEditorFocus"
								use-custom-image-handler @image-added="handleImageAdded" @blur="onEditorBlur"
								@selection-change="onSelectionChange" />

						</el-form-item>
						<el-form-item label="技术效果图" prop="img">
							<el-upload :action="baseUrl+'file/upload_one'" :data="{dir:'achivement'}"
								:on-exceed="beforeUpload" :on-success="handleAvatarSuccess" list-type="picture-card"
								:file-list="img_list" :before-remove="remove_img" :limit="4">
								<i slot="default" class="el-icon-plus"></i>
							</el-upload>
							<el-dialog :visible.sync="dialogVisible">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
						</el-form-item>
						<el-form-item label="合作方式" prop="cooperation_type">
							<el-checkbox-group v-model="ruleForm.cooperation_type">
								<el-checkbox v-for="(v,k) in cate.cooperation_type" :key="k" :label="v">
								</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item label="专利状态" prop="zl_status">
							<el-radio-group v-model="ruleForm.zl_status">
								<el-radio :label="1">是</el-radio>
								<el-radio :label="2">否</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="国家" prop="country">
							<el-select v-model="ruleForm.country" placeholder="请选择" style="width: 200px;">
								<el-option v-for="(v,k) in cate.country" :key='k' :value="v" :label="v">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="应用领域" prop="yyly">

							<vue-editor style="width:690px;" v-model="ruleForm.yyly" @focus="onEditorFocus"
								use-custom-image-handler @image-added="handleImageAdded" @blur="onEditorBlur"
								@selection-change="onSelectionChange" />
						</el-form-item>
						<el-form-item label="技术成熟度" prop="maturity">
							<el-radio-group v-model="ruleForm.maturity">
								<el-radio :label="v" v-for="(v,k) in cate.maturity" :key="k"></el-radio>

							</el-radio-group>
						</el-form-item>
						<el-form-item label="关键词" prop="tag">
							<el-tag :key="tag" v-for="tag in ruleForm.tag" closable :disable-transitions="false"
								@close="handleClose(tag)">
								{{tag}}
							</el-tag>
							<el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput"
								size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
							</el-input>
							<div v-else>
								<el-button class="button-new-tag" size="small" @click="showInput">点击输入标签
								</el-button>
							</div>

						</el-form-item>
						<el-form-item label="是否设置为精选" prop="is_recommend">

							<el-switch v-model="ruleForm.is_recommend" active-color="#13ce66" inactive-color="#f3f3f3">
							</el-switch>

						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
							<el-button @click="save()">保存草稿</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import Left from "../user/Left.vue"
	import {
		VueEditor
	} from 'vue2-editor';
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Left,
			VueEditor
		},
		data() {
			return {
				ruleForm: {
					title: '',
					industry: '',
					property_right: '',
					xmhjsqk: '',
					img: [],
					country: '',
					cooperation_type: [],
					reference_price: '',
					zl_status: 1,
					yyly: '',
					maturity: '',
					tag: [],
					is_recommend: false
				},
				cate: {
					industry: [],
					maturity: [],
					country: [],
					cooperation_type: [],
				},
				inputVisible: false,
				inputValue: '',
				img_list: [],
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				rules: {
					title: [{
						required: true,
						message: '请输入技术名称'
					}],
					number: [{
						required: true,
						message: '请输入项目编号'
					}],
					industry: [{
						required: true,
						message: '请选择适用行业'
					}],
					property_right: [{
						required: true,
						message: '请选择是否有知识产权'
					}],
					xmhjsqk: [{
						required: true,
						message: '请填写项目简介'
					}],
					img: [{
						required: true,
						message: '请填上传成果图片'
					}],
					country: [{
						required: true,
						message: '请选择国家'
					}],
					cooperation_type: [{
						required: true,
						message: '请选择合作方式'
					}],
					zl_status: [{
						required: true,
						message: '请选择专利状态'
					}],
					yyly: [{
						required: true,
						message: '请填写应用领域'
					}],
					maturity: [{
						required: true,
						message: '请选择技术成熟度'
					}],
					tag: [{
						required: true,
						message: '请输入关键词'
					}]
				},
				industrial: [],
				id: '',
			}
		},
		mounted() {
			if (typeof(this.$route.query.id) != 'undefined') {
				this.id = this.$route.query.id;
				this.get_detail();
			}
			if (this.ruleForm.id) {
				var ruleForm = localStorage.getItem('ruleForm' + this.ruleForm.id)
			} else {
				ruleForm = localStorage.getItem('ruleForm')
			}

			if (ruleForm) {
				this.ruleForm = JSON.parse(ruleForm);
				this.get_img_list()
			}
			this.get_cate();
		},
		methods: {
			beforeUpload() {
				this.$message.error('最多上传四张图片');
			},
			remove_img(file) {
				for (var i = 0; i < this.ruleForm.img.length; i++) {
					if (file.url == this.ruleForm.img[i]) {
						this.ruleForm.img.splice(i, 1)
					}
				}
			},
			goBack() {
				// this.$router.back();
				this.$router.push({
					path: '/'
				})
			},
			save() {
				if (this.ruleForm.id) {
					localStorage.setItem('ruleForm' + this.ruleForm.id, JSON.stringify(this.ruleForm))

				} else {
					localStorage.setItem('ruleForm', JSON.stringify(this.ruleForm))

				}
				this.$message.info('保存成功')

			},
			handleImageAdded(file, Editor, cursorLocation, resetUploader) {
				console.log(file);
				var formData = new FormData();
				formData.append('file', file);
				formData.append('dir', 'news');
				this.$http
					.post('file/upload_one', formData, {
						headers: {
							"Content-Type": "multipart/form-data;"
						}
					})
					.then(res => {
						console.log(res);
						let url = res.data.img_url; // Get url from response
						Editor.insertEmbed(cursorLocation, 'image', url);
						resetUploader();
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			onEditorBlur(quill) {
				console.log('editor blur!', quill);
			},
			onEditorFocus(quill) {
				console.log('editor focus!', quill);
			},
			onSelectionChange(range) {
				console.log('selection change!', range);
			},
			get_cate() {
				this.$http_qm.post('achievement2/cate', {}).then(res => {
					this.cate = res.data;
				}).catch(err => {
					console.log(err)
				})
			},
			get_detail() {
				this.$http_qm.post('achievement2/detail', {
					id: this.id
				}).then(res => {
					if (res.code == 200) {
						this.ruleForm = res.data;

						if (res.data.tag && res.data.tag.length > 0) {
							this.ruleForm.tag = res.data.tag;
						} else {
							this.ruleForm.tag = [];
						}
						if (res.data.zl_status == 1) {
							this.ruleForm.property_right = '是'
						} else {
							this.ruleForm.property_right = '否'
						}

						this.get_img_list()


					}

				}).catch(err => {
					console.log(err);
				})
			},
			get_img_list() {
				var img_list = []
				var ruleForm = this.ruleForm
				if (Array.isArray(ruleForm.img)) {
					ruleForm.img.forEach(function(v) {
						img_list.push({
							url: v
						})
					})
				}
				this.img_list = img_list;
				this.$forceUpdate();
			},
			handleAvatarSuccess(res, file) {
				if (res.code == 200) {
					this.imageUrl = URL.createObjectURL(file.raw);
					this.ruleForm.img.push(res.data.img_url);
				} else {
					this.$message.error(res.msg)
				}
			},
			discuss_change(k) {
				if (this.cooperation_type_arr[k].discuss) {
					this.ruleForm.reference_price = '面议'
				}
			},
			price_change(v) {
				this.ruleForm.reference_price = v;
			},

			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					let add_data = this.ruleForm;
					console.log(add_data);
					if (!valid) {
						console.log('error submit!!');
						return false;
					}
					add_data.reference_price = '面议'
					let url = '';
					if (this.id == '') {
						url = 'achievement2/add'
						var post = this.$http_qm.post(url, add_data)
					} else {
						url = 'achievement2/update'
						add_data.id = this.id
						post = this.$http_qm.post(url, add_data)
					}
					post.then(res => {
						this.$message.info(res.msg)
						if (res.code == 200) {
							this.$router.push({
								path: '/achivement/admin'
							});
						}
					}).catch(err => {
						console.log(err)
					})
				});
			},
			handleClose(tag) {
				this.ruleForm.tag.splice(this.ruleForm.tag.indexOf(tag), 1);
			},
			showInput() {
				this.inputVisible = true;
				this.$nextTick(() => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},
			handleInputConfirm() {
				let inputValue = this.inputValue;
				if (inputValue) {
					this.ruleForm.tag.push(inputValue);
				}
				this.inputVisible = false;
				this.inputValue = '';
			},
			// 图片上传
			handleRemove(file) {
				console.log(file);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			}
		}
	}
</script>

<style lang="less">
	#achievemenAdd {
		color: #666;
		background: #f5f5f5;

		.container {
			margin: 0 auto;
			width: 1200px;
			margin-top: 26px;
			margin-bottom: 20px;
			overflow: hidden;

			.grzx_left {
				width: 160px;
				height: auto;
				float: left;
				overflow: hidden;
			}

			.grzx-right {
				width: 1020px;

				.grzx-box {
					padding: 20px;
					background-color: #fff;
					margin-bottom: 20px;

					.grzx-wxts {
						height: 120px;
						border: #ffeed4 1px solid;
						margin-bottom: 20px;
						background-color: #fffbf5;

						.wxts-wzts {
							width: 720px;
							height: 100px;
							margin-top: 10px;
							float: left;
							margin-left: 30px;

							h3 {
								height: 30px;
								font-size: 16px;
								color: #f9651e;
								line-height: 30px;
							}

							p {
								width: 100%;
								height: 24px;
								font-size: 14px;
								color: #666666;
								line-height: 24px;

							}
						}
					}

					.el-form-item__label {
						font-size: 16px;
						font-family: "微软雅黑";
						color: #333;
					}

					.el-input__inner {
						width: 100%;
					}

					.el-radio-group {
						align-items: flex-start !important;
					}

					.el-tag {
						margin-left: 10px;
					}

					.button-new-tag {
						margin-left: 10px;
						height: 32px;
						line-height: 30px;
						padding-top: 0;
						padding-bottom: 0;
					}

					.input-new-tag {
						width: 90px;
						margin-left: 10px;
						vertical-align: bottom;
					}

					.join {
						flex-direction: column;

						.price {
							display: flex;
							flex-direction: row;
							align-items: center;

							span {
								font-size: 14px;
							}

							.el-input {
								margin: 0 10px;
							}

							.el-checkbox {
								margin-left: 30px;
							}
						}
					}
				}
			}

			.fr {
				float: right;
			}
		}

	}
</style>
