<template>
	<div id="achievemenAdd">
		<div class="head">
			<el-page-header @back="goBack" content="成果管理">
			</el-page-header>
		</div>
		<div class="grzx-bgcont">
			<div class="container">
				<div class="grzx_left">
					<Left></Left>
				</div>
				<div class="grzx-right fr">
					<div class="user-box">
						<div class="user-title">
							<i></i>
							<span>我发布的成果</span>
						</div>
						<div class="search">
							<el-input v-model="achievements.param.keyword" placeholder="请输入关键词搜索"></el-input>
							<el-button @click="do_serch()" type="primary">搜索</el-button>
						</div>


						<el-table :data="achievements.list" style="width: 100%" v-if="achievements.list.length>0">
							<el-table-column label="成果图" prop="img" align="center">
								<template slot-scope="scope">
									<el-image style="width: 134px; height: 84px"
										:src="Array.isArray(scope.row.img)?scope.row.img[0]:scope.row.img" fit="cover">
									</el-image>
								</template>
							</el-table-column>
							<el-table-column label="技术名称" prop="title" align="center">
							</el-table-column>
							<el-table-column label="行业" prop="industry" align="center">
							</el-table-column>
							<el-table-column label="合作方式" prop="cooperation_type" align="center">
							</el-table-column>
							<el-table-column label="成熟度" prop="maturity" align="center">
							</el-table-column>
							<el-table-column label="操作" align="center">
								<template slot-scope="scope">
									<el-button style="margin-right: 10px;" size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>

									<el-popconfirm title="确定删除吗"  @confirm="handleDelete(scope.$index,scope.row.id)">
										<el-button slot="reference" size="mini" type="danger">删除</el-button>
									</el-popconfirm>

								</template>
							</el-table-column>
						</el-table>
						<div class="no-publish" v-else>
							<p>您还没有发布过技术成果哦，快去发布技术成果吧~</p>
							<router-link to="/achivement/add" class="btn-box">
								<div class="btn btn-rOrange">免费发布</div>
							</router-link>
						</div>
						<div class="page">

							<el-pagination background @size-change="handleSizeChange"
								@current-change="handleCurrentChange" :page-size="achievements.param.limit"
								layout="total,prev, pager, next" :total="achievements.count">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Left from "../user/Left.vue"
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Left
		},
		data() {
			return {
				activeName: 'first',
				currentPage: 1,
				TabCur: 0,
				company_id: '',
				achievements: {
					list: [],
					count: 0,
					param: {
						page: 1,
						limit: 5,
						request_page: 'admin',
						keyword: '',
					}
				}
			}
		},
		mounted() {

			this.get_list();
		},
		methods: {
			goBack() {
				// this.$router.back();
				this.$router.push({
					path: '/'
				})
			},
			do_serch() {
				this.achievements.param.page = 1;
				this.get_list()
			},
			get_list() {
				let list = this.achievements;
				this.$http_qm.post('achievement2/list', list.param).then(res => {
					if (res.code == 200) {
						console.log(res.count);
						list.list = res.data;
						this.achievements.count = res.count;
						this.$forceUpdate();
					} else {
						this.$message.info(res.msg)
					}

				}).catch(err => {
					console.log(err)
				})
			},
			handleClick(tab) {
				this.TabCur = tab.index;
				this.get_list(this.TabCur);
			},
			handleEdit(id) {
				this.$router.push({
					'path': '/achivement/add',
					query: {
						id: id
					}
				})
			},
			handleDelete(index, id) {
				console.log(index, id);

				this.$http_qm.post('achievement2/delete', {
					id: id
				}).then(res => {
					this.achievements.list.splice(index, 1);
					this.$message.info(res.msg)
				}).catch(err => {
					console.log(err);
				})
			},
			// 页码
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {

				let list = this.achievements;
				list.param.page = val;
				this.get_list();
			}
		}
	}
</script>

<style lang="less">
	.search {
		.el-input {
			width: 400px;
			margin-right: 20px;
		}

		margin: 20px 0px;
	}

	#achievemenAdd {
		color: #666;


		.grzx-bgcont {
			margin: 0 auto;
			width: 1200px;
			background-color: #f5f5f5;

			.container {
				margin: 0 auto;
				width: 1200px;
				margin-top: 26px;
				margin-bottom: 20px;
				overflow: hidden;
				display: flex;

				.grzx_left {
					width: 160px;
					height: auto;
					float: left;
					overflow: hidden;
				}

				.grzx-right {
					flex: 1;
					margin-left: 20px;

					.user-box {
						padding: 20px;
						background-color: #fff;
						margin-bottom: 20px;
						min-height: 492px;

						.user-title {
							margin-bottom: 10px;
							line-height: 16px;

							i {
								display: inline-block;
								width: 4px;
								height: 16px;
								background: #00a3e7;
								margin-right: 10px;
								vertical-align: top;
							}

							span {
								font-size: 16px;
								color: #333;
								font-weight: 700;
							}
						}

						td {
							border: none;
						}

						.no-publish {
							padding: 30px 20px 30px 30px;
							display: flex;
							flex-direction: column;
							justify-content: center;

							p {
								font-family: 'Microsoft Yahei', '\5b8b\4f53', sans-serif, tahoma, arial, 'Hiragino Sans GB';
								font-size: 14px;
								line-height: 1.42857143;
								color: #666;
								text-align: center;
							}

							.btn-box {
								margin: 15px auto;

								.btn-rOrange {
									display: inline-block;
									vertical-align: middle;
									touch-action: manipulation;
									cursor: pointer;
									text-align: center;
									white-space: nowrap;
									border-radius: 20px;
									border: 1px solid #ff7720;
									background-color: #ffffff;
									color: #ff7720;
									font-size: 12px;
									padding: 4px 65px;
								}
							}
						}
					}
				}

			}
		}
	}
</style>
